import { render, staticRenderFns } from "./wxLogin.vue?vue&type=template&id=5eafdacd&scoped=true"
import script from "./wxLogin.vue?vue&type=script&lang=js"
export * from "./wxLogin.vue?vue&type=script&lang=js"
import style0 from "./wxLogin.vue?vue&type=style&index=0&id=5eafdacd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eafdacd",
  null
  
)

export default component.exports